import React from 'react';
import {
  List, Datagrid, TextField, Edit,
  Show,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  BooleanField,
  required,
  ShowButton,
  BooleanInput,
} from 'react-admin';

const UserFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput label="Name" source="id" reference="users" allowEmpty alwaysOn>
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </Filter>
);

export const UserList = (props) => (
  <List
    {...props}
    filters={<UserFilter />}
    >
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
      <BooleanField source="enabled" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="username" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const UserCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="username" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
      <TextInput source="password-again" type="password" validate={required()} />
      <BooleanInput source="enabled" defaultValue />
    </SimpleForm>
  </Create>
);

export const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <BooleanField source="enabled" />
    </SimpleShowLayout>
  </Show>
);
