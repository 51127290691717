import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Show,
  Filter,
  Pagination,
  SimpleShowLayout,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  AutocompleteInput,
  Create,
  Edit,
  SimpleForm,
  BooleanField,
  BooleanInput,
  DateField,
  TextInput,
  required,
  ShowButton,
} from 'react-admin';

import { DateTimeInput } from 'react-admin-date-inputs';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const EXPIRING_DAYS = 30;

const licenseStyles = makeStyles({
  valid: { background: '#9eff8f' },
  expire_soon: { background: '#ffef63' },
  expired: { background: '#ff8ea3' },
  disabled: { background: '#aaaaaa' },
});

function getLicenseStyleClass(date, option, disabled = false) {
  const licenseDate = new Date(date);
  const now = new Date();
  const expireSoon = new Date();
  expireSoon.setDate(now.getDate() + EXPIRING_DAYS);

  if (option === 'disabled') {
    return disabled;
  }
  if (option === 'valid') {
    return licenseDate > expireSoon;
  }
  if (option === 'expire_soon') {
    return licenseDate > now && licenseDate < expireSoon;
  }
  if (option === 'expired') {
    return licenseDate < now;
  }

  return false;
}

const ColoredDateField = (props) => {
  const classes = licenseStyles();
  return (
    <DateField
      className={classnames({
        [classes.valid]: getLicenseStyleClass(props.record[props.source], 'valid'),
        [classes.expire_soon]: getLicenseStyleClass(props.record[props.source], 'expire_soon'),
        [classes.expired]: getLicenseStyleClass(props.record[props.source], 'expired'),
        [classes.disabled]: getLicenseStyleClass(props.record[props.source], 'disabled', !props.record['enabled']),
      })}
      showTime
      {...props}
    />
  );
};

const LicenseFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="client" reference="clients" allowEmpty alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="ZeroTier" source="id" reference="licenses" allowEmpty>
      <AutocompleteInput optionText="zt" />
    </ReferenceInput>
    <ReferenceInput label="Hostname" source="id" reference="licenses" allowEmpty>
      <AutocompleteInput optionText="hst" />
    </ReferenceInput>
    <BooleanInput label="Enabled" source="enabled" defaultValue="true" />
  </Filter>
);

const LicensePagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

export const LicenseList = (props) => (
  <List
    {...props}
    filters={<LicenseFilter />}
    pagination={<LicensePagination />}
    perPage={50}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="zt" label="ZeroTier adresa" />
      <TextField source="hwid" label="Hardware ID" />
      <TextField source="hst" label="Hostname" />
      <ReferenceField source="client" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="enabled" />
      <DateField source="created" />
      <ColoredDateField source="expiration" />
      <ShowButton />
    </Datagrid>
  </List>
);

export const LicenseCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput
        source="zt"
        label="ZeroTier adresa"
        validate={required()}
        resettable
      />
      <TextInput source="hwid" resettable label="Hardware ID" />
      <TextInput
        source="hst"
        validate={required()}
        resettable
        label="Hostname"
      />
      <ReferenceInput source="client" reference="clients">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <DateTimeInput
        source="expiration"
        label="Expirace"
        validate={required()}
        options={{
          format: 'dd/MM/yyyy, HH:mm:ss',
          ampm: false,
          clearable: true,
        }}
      />
      <BooleanInput source="enabled" initialValue="true" />
    </SimpleForm>
  </Create>
);

export const LicenseEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="edit">
      <TextInput
        source="zt"
        label="ZeroTier adresa"
        validate={required()}
        resettable
      />
      <TextInput source="hwid" resettable label="Hardware ID" />
      <TextInput
        source="hst"
        validate={required()}
        resettable
        label="Hostname"
      />
      <TextInput source="client" resettable label="Client" />
      <DateTimeInput
        source="expiration"
        label="Expirace"
        validate={required()}
        options={{
          format: 'dd/MM/yyyy, HH:mm:ss',
          ampm: false,
          clearable: true,
        }}
      />
      <BooleanInput source="enabled" />
    </SimpleForm>
  </Edit>
);

export const LicenseShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <BooleanField source="enabled" />
      <TextField source="note" label="Note" />
      <TextField source="zt" label="ZeroTier adresa" />
      <TextField source="hwid" label="Hardware ID" />
      <TextField source="hst" label="Hostname" />
      <TextField source="client" label="Client" />
      <DateField source="created" showTime="true" />
      <DateField source="expiration" showTime="true" />
      <TextField source="token" />
    </SimpleShowLayout>
  </Show>
);
