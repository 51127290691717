// in src/authProvider.js
import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR
} from 'react-admin';
import Settings from './settings';

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${Settings.SERVER}/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    return fetch(request)
      .then((response) => {
        console.log('response', response);
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ accessToken }) => {
        localStorage.setItem('token', accessToken);
        console.log('token stored', accessToken);
      });
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('token');
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_ERROR) {
    localStorage.removeItem('token');
    return Promise.reject();
  }

  return Promise.resolve();
};
